import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Engineering = ({ pageContext }) => (
  <Layout pageContext={pageContext}>
    <SEO title="Инженерные сети" />

    <section id="engineering" className="pb80 pt100 bg-dark uslugi engineering">

      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="section-title mb25">
              <h1>Инженерные сети</h1>
              <p>Ни одно современное здание не обходится без инженерных сетей. Именно они обеспечивают комфорт людей, а также позволяют применять разную технику и оборудование. Поэтому устройство наружных и внутренних инженерных коммуникаций – один из самых ответственных этапов строительства. Компания «СК МАСТЕР» предлагает профессиональное проектирование и монтаж инженерных систем.</p>
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h2 className="color-style2">
              Виды коммуникаций
              </h2>
            <p>Инженерные сети – это системы, включающие в себя специализированное оборудование и инфраструктуру. Их назначение различно. Это может быть подача электроэнергии, воды (горячей и холодной), отведение стоков и т.д.</p>
            <p>Основные виды инженерных коммуникаций:</p>
            <ul className="list-group checklist">
              <li>отопление;</li>
              <li>электроснабжение;</li>
              <li>канализация;</li>
              <li>водоснабжение;</li>
              <li>слаботочные сети.</li>
            </ul>
          </div>
          <div className="col-md-12 col-sm-12">
            <h2 className="color-style2">
              Устройство инженерных сетей
              </h2>
            <p>Инженерные работы состоят из 2 этапов: проектирование и монтаж. На первом этапе учитываются характеристики почвы, климатические факторы, особенности эксплуатации здания и т.д. при этом проекты каждой инженерной сети должны быть между собой четко согласованы. Недочеты и упущения, допущенные при проектировании, грозят серьезными последствиями. При монтаже коммуникаций необходимо соблюдать требования проекта. Для этой работы должны использоваться современное оборудование и высококачественные материалы.</p>
            <p>Профессионалы, работающие в «СК МАСТЕР», имеют большой опыт в сфере инженерных работ и используют самую лучшую технику и стройматериалы. Нам удалось сократить до минимума стоимость инженерных работ, благодаря налаженным контактам с зарекомендовавшими себя производителями и поставщиками, а также за счет оптимизации внутренней работы.</p>
            <p>Кроме того, мы всегда соблюдаем оговоренные сроки и готовы решать задачи любой сложности! Для уточнения подробностей, свяжитесь с нашими менеджерами любым удобным способом.</p>
          </div>
        </div>
        <p className='exclamation'>
          «СК МАСТЕР» - когда инженерные сети – это надолго!
        </p>
      </div>
    </section>

  </Layout >
)

export default Engineering
